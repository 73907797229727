export const current_team_photo = [
  {
    id: 1,
    type: 'executive_committee',
    src: '/photos/members/IMG_20230617_122843_Bokeh.jpg',
    name: 'C A Akhil',
    post: 'CHAIRPERSON',
    linkedin: 'https://www.linkedin.com/in/akhil-c-a-642606246',
    gmail: 'akhilca232002@gmail.com',
  },
  {
    id: 2,
    type: 'executive_committee',
    src: '/photos/members/Prajwal.jpg',
    name: 'Prajwal K',
    post: 'VICE CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/prajwal2727?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    gmail: 'prajwalrao123@gmail.com',
  },
  {
    id: 3,
    type: 'executive_committee',
    src: '/photos/members/Sneha N Angadi.jpg',
    name: 'Sneha N Angadi',
    post: 'GENERAL SECRETARY',
    linkedin:
      'https://www.linkedin.com/in/sneha-n-angadi-055a88246?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app  ',
    gmail: 'vreachsneha23@gmail.com',
  },
  {
    id: 4,
    type: 'executive_committee',
    src: '/photos/members/K Ganapathi sharma.jpg',
    name: 'K Ganapathi Sharma',
    post: 'SAC CO-ORDINATOR',
    linkedin: 'www.linkedin.com/in/ganapathi-sharma',
    gmail: 'ganapathisharma2003@gmail.com',
  },
  {
    id: 5,
    type: 'executive_committee',
    src: '/photos/members/Sohan S.jpg',
    name: 'Sohan S',
    post: 'PUBLICITY LEAD',
    linkedin: 'www.linkedin.com/in/sohan-s',
    gmail: 'sohansnair1563717@gmail.com',
  },
  {
    id: 6,
    type: 'executive_committee',
    src: '/photos/members/Meghana.jpg',
    name: 'Meghana',
    post: 'REPORTING LEAD',
    linkedin:
      's://www.linkedin.com/in/meghana-ballal-735314212?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'meghanaballal02@gmail.com',
  },
  {
    id: 7,
    type: 'executive_committee',
    src: '/photos/members/Deeksha Hegde.PNG',
    name: 'Deeksha Hegde',
    post: 'EVENT MANAGER',
    linkedin:
      'https://www.linkedin.com/in/deeksha-hegde-890407210?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app ',
    gmail: 'deekshahegde321@gmail.com',
  },

  {
    id: 9,
    type: 'executive_committee',
    src: '/photos/members/K Vineeth Rao.PNG',
    name: 'K Vineeth Rao',
    post: 'JOINT SECRETARY',
    linkedin: 'https://www.linkedin.com/in/vineeth-rao-kuthnikar-bb1109284  ',
    gmail: 'vineethk.rao@gmail.com',
  },
  {
    id: 10,
    type: 'executive_committee',
    src: '/photos/members/Manish_Bhargav.jpg',
    name: 'Manish Bhargav',
    post: 'TREASURER',
    linkedin: 'https://linkedin.com/in/manish-bhargav-42a827297',
    gmail: 'manishbhargav1014@ieee.org ',
  },
  {
    id: 11,
    type: 'executive_committee',
    src: '/photos/members/Chaithanya P.jpg',
    name: 'Chaithanya P',
    post: 'CHIEF STUDENT CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/chaithanya-p-58b718296',
    gmail: 'chaithanyap04@gmail.com',
  },
  {
    id: 12,
    type: 'executive_committee',
    src: '/photos/members/ABDULRAHIMAN CHOUDHARI.jpg',
    name: 'Abdulrahiman Choudhari',
    post: 'STUDENT CO-ORDINATOR',
    linkedin: 'https://bit.ly/Linkedin_Abdulrahiman',
    gmail: 'abdulrahimankc123@gmail.com',
  },
  {
    id: 13,
    type: 'executive_committee',
    src: '/photos/members/P Pradeep Aradhya.jpg',
    name: 'P Pradeep Aradhya',
    post: 'STUDENT CO-ORDINATOR',
    linkedin:
      'https://www.linkedin.com/in/pradeep-aradhya-1b1935287?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'pradeeparadhya739@gmail.com',
  },
  {
    id: 14,
    type: 'executive_committee',
    src: '/photos/members/Rachana.jpg',
    name: 'Rachana K R',
    post: 'PUBLICITY CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/rachana-k-r-15a79825b',
    gmail: 'rachanaravi193@gmail.com',
  },
  {
    id: 15,
    type: 'executive_committee',
    src: '/photos/members/Pushpa N B .jpg',
    name: 'Pushpa N B',
    post: 'REPORTING TEAM',
    linkedin:
      'https://www.linkedin.com/in/pushpa-n-b-13490b278?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'pushpanb246@gmail.com',
  },
  {
    id: 16,
    type: 'executive_committee',
    src: '/photos/members/Sonali shiladhar wali.jpg',
    name: 'Sonali Shiladhar Wali',
    post: 'REPORTING TEAM',
    linkedin: 'https://www.linkedin.com/in/sonali-shiladhar-wali-226621279',
    gmail: 'sonaliwali962004@gmail.com',
  },

  {
    id: 17,
    type: 'MEMBERSHIP DEVELOPMENT COMMITTEE',
    src: '/photos/members/Prakruthi K P .jpg',
    name: 'Prakruti K P',
    post: 'SBMDC CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/prakruthi-k-p-787313212?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'prakruthikp17@gmail.com',
  },
  {
    id: 18,
    type: 'MEMBERSHIP DEVELOPMENT COMMITTEE',
    src: '/photos/members/Sumukh J Kowligi.jpg',
    name: 'Sumukh J Kowligi',
    post: 'SBMDC VICE CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/sumukhkowligi?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'sumukhkowligi@gmail.com',
  },
  {
    id: 19,
    type: 'MEMBERSHIP DEVELOPMENT COMMITTEE',
    src: '/photos/members/Prashamsa.jpg',
    name: 'I Prashamsa Prabhu',
    post: 'SECRETARY',
    linkedin: 'https://www.linkedin.com/in/i-prashamsa-prabhu-a335b2290/',
    gmail: 'iprashamsaprabhu@gmail.com',
  },
  {
    id: 20,
    type: 'MEMBERSHIP DEVELOPMENT COMMITTEE',
    src: '/photos/members/harshi 1.jpeg',
    name: 'M S Harshitha',
    post: 'AMBASSADOR',
    linkedin: 'https://www.linkedin.com/in/m-s-harshitha-657306294',
    gmail: 'harshitha8712@gmail.com',
  },
  {
    id: 21,
    type: 'Electron Devices Society',
    src: '/photos/members/Abdullah Meeran MH.jpg',
    name: 'Abdullah Meeran M H',
    post: 'CHAIRPERSON',
    linkedin: 'https://www.linkedin.com/in/abdullah-meeran-mh-31a913236',
    gmail: 'abdullahmeeran18@gmail.com ',
  },
  {
    id: 22,
    type: 'Electron Devices Society',
    src: '/photos/members/P A Anshad.jpg',
    name: 'P A Anshad',
    post: 'VICE CHAIRPERSON',
    linkedin: 'https://www.linkedin.com/in/anshad-p-a-66329a213/',
    gmail: 'anshadpa720@gmail.com',
  },
  {
    id: 23,
    type: 'Electron Devices Society',
    src: '/photos/members/Ashwin V Bhandari.JPG',
    name: 'Ashwin Vijayakumar Bhandari',
    post: 'SENIOR TECHNICAL CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/ashwin-bhandari396/',
    gmail: 'ashwinvbhandari@gmail.com',
  },
  {
    id: 24,
    type: 'Electron Devices Society',
    src: '/photos/members/Pranav Shandilya.jpg',
    name: 'Pranav Shandilya',
    post: 'SECRETARY',
    linkedin:
      'https://www.linkedin.com/in/pranavshan19?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'shandilyapranav94@gmail.com',
  },
  {
    id: 25,
    type: 'Electron Devices Society',
    src: '/photos/members/vallish s (2).jpg',
    name: 'Vallish S',
    post: 'JOINT SECRETARY',
    linkedin:
      'https://www.linkedin.com/in/vallish-s-31491a23a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'vallishs52003@gmail.com',
  },
  {
    id: 26,
    type: 'Electron Devices Society',
    src: '/photos/members/Priyanshu Raj.jpg',
    name: 'Priyanshu Raj',
    post: 'TECHNICAL CO-ORDINATOR',
    linkedin:
      'https://www.linkedin.com/in/priyanshu-raj-b558a522a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'priyanshuraj5510@gmail.com',
  },
  {
    id: 27,
    type: 'women in engineering',
    src: '/photos/members/Smrithi Bhat.jpg',
    name: 'Smrithi Bhat',
    post: 'CHAIRPERSON',
    linkedin: 'https://www.linkedin.com/in/smrithi-bhat-139313212/ ',
    gmail: 'smrithib0602@gmail.com',
  },
  {
    id: 28,
    type: 'women in engineering',
    src: '/photos/members/IMG_9439.JPG',
    name: 'Umar Ahmed',
    post: 'VICE CHAIRPERSON',
    linkedin: 'https://www.linkedin.com/in/umar-ahmad-7a0b73245 ',
    gmail: 'umarahmad10786@gmail.com',
  },

  {
    id: 29,
    type: 'women in engineering',
    src: '/photos/members/neha_tg.jpg',
    name: 'Neha T G',
    post: 'Senior Technical Coordinator',
    linkedin: 'https://www.linkedin.com/in/neha-t-g-832340231',
    gmail: 'nehatg504@gmail.com',
  },
  {
    id: 30,
    type: 'women in engineering',
    src: '/photos/members/Ananya A.jpg',
    name: 'Ananya A',
    post: 'SENIOR EVENT CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/ananyaa21',
    gmail: 'ananyaachendu21@gmail.com',
  },
  {
    id: 31,
    type: 'women in engineering',
    src: '/photos/members/Sinchana Y.jpg',
    name: 'Sinchana Y',
    post: 'SECRETARY',
    linkedin: 'https://www.linkedin.com/in/sinchana-y-243811278',
    gmail: 'sinchany066@gmail.com',
  },
  {
    id: 32,
    type: 'women in engineering',
    src: '/photos/members/Vaibhav_Kammar.jpg',
    name: 'Vaibhav Kammar',
    post: 'JOINT SECRETARY',
    linkedin:
      'https://www.linkedin.com/in/vaibhav-kammar-b4ba86241?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'vaibhavkammar06@gmail.com',
  },
  {
    id: 33,
    type: 'women in engineering',
    src: '/photos/members/Siri_J.jpg',
    name: 'Siri J',
    post: 'TECHNICAL CO-ORDINATOR',
    linkedin:
      'https://www.linkedin.com/in/siri-j-0b250b230?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'sirilj20062003@gmail.com',
  },
  {
    id: 34,
    type: 'women in engineering',
    src: '/photos/members/Mythri Manjunath.jpg',
    name: 'Mythri Manjunath',
    post: 'EVENT CO-ORDINATOR',
    linkedin:
      'https://www.linkedin.com/in/mythri-manjunath-8b1a1a294?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app  ',
    gmail: 'mythrimanjunath2003@gmail.com',
  },
  {
    id: 35,
    type: 'robotics and automation society',
    src: '/photos/members/Gururaj Deepak Todurkar .jpeg',
    name: 'Gururaj Deepak Todurkar',
    post: 'CHAIR-PERSON',
    linkedin: 'https://www.linkedin.com/in/gururaj-todurkar-b0579a240',
    gmail: 'gururajdtodurkar7@gmail.com',
  },
  {
    id: 36,
    type: 'robotics and automation society',
    src: '/photos/members/krishnakumar.jpg',
    name: 'Krishnakumar Balachandra Bhat',
    post: 'VICE CHAIR-PERSON ',
    linkedin: 'https://www.linkedin.com/in/20181729/',
    gmail: 'krishnakumarbhat02@gmail.com',
  },
  {
    id: 37,
    type: 'robotics and automation society',
    src: '/photos/members/Darshan D Kerkar .jpg',
    name: 'Darshan D Kerkar',
    post: 'SENIOR TECHNICAL CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/darshan-kerkar-94a26427a',
    gmail: 'darshankerkar04@gmail.com',
  },
  {
    id: 38,
    type: 'robotics and automation society',
    src: '/photos/members/Sudhanva N.Kulkarni.jpg',
    name: 'Sudhanva N Kulkarni',
    post: 'SECRETARY',
    linkedin: 'https://www.linkedin.com/in/sudhanva-kulkarni-a922bb220',
    gmail: 'ksudhanva03@gmail.com',
  },
  {
    id: 39,
    type: 'robotics and automation society',
    src: '/photos/members/Amit_Verma.jpg',
    name: 'Amit Verma',
    post: 'JOINT SECRETARY',
    linkedin: 'https://www.linkedin.com/in/amit-verma-1b5677249/ ',
    gmail: 'amitverma5262.02@gmail.com',
  },
  {
    id: 40,
    type: 'robotics and automation society',
    src: '/photos/members/Rishabh raj.jpeg',
    name: 'Rishabh Raj',
    post: 'TECHNICAL CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/rishabh-raj-468629297',
    gmail: 'rishabh1232326@gmail.com',
  },
  {
    id: 40,
    type: 'web development board',
    src: '/photos/members/IMG20210307144705 1.jpg',
    name: 'Debojyoti Koley',
    post: 'CHAIRPERSON',
    linkedin: 'https://www.linkedin.com/in/debojyoti-koley-2268071a8/',
    gmail: 'vineethk.rao@gmail.com',
  },
  {
    id: 41,
    type: 'web development board',
    src: '/photos/members/subroto.jpg',
    name: 'Subroto Koiry',
    post: 'CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/subroto-koiry  ',
    gmail: 'vineethk.rao@gmail.com',
  },
  {
    id: 42,
    type: 'editorial board',
    src: '/photos/members/IMG20220908082302 (2).jpg',
    name: 'Pushpesh Joshi',
    post: 'CHAIRPERSON',
    linkedin: 'https://www.linkedin.com/in/pushpesh-joshi-5493101b8',
    gmail: 'joshi.pushpesh09@gmail.com',
  },
  {
    id: 43,
    type: 'editorial board',
    src: '/photos/members/Priyanshu  Tripathi .jpg',
    name: 'Priyanshu Kumar Tripathi',
    post: 'VICE CHAIRPERSON',
    linkedin: 'https://www.linkedin.com/in/priyanshu-kr-tripathi-4658a822a',
    gmail: 'vashu.tripathi23@gmail.com',
  },
  {
    id: 44,
    type: 'editorial board',
    src: '/photos/members/Vindya K.jpg',
    name: 'Vindya K',
    post: 'SENIOR EDITOR',
    linkedin: 'https://www.linkedin.com/in/vindya-k/',
    gmail: 'kvindya404@gmail.com',
  },
  {
    id: 45,
    type: 'editorial board',
    src: '/photos/members/IMG-20231028-WA0076.jpg',
    name: 'Adya S',
    post: 'SENIOR EDITOR ',
    linkedin: 'https://www.linkedin.com/in/adya-sujeet-168288254  ',
    gmail: 'adyasujeet@yahoo.com',
  },
  {
    id: 46,
    type: 'editorial board',
    src: '/photos/members/Neethushree GN.PNG',
    name: 'Neethushree G N',
    post: 'GRAPHIC DESIGNER',
    linkedin: 'https://www.linkedin.com/in/neethu-shree-g-n-97a0a3255/',
    gmail: 'neethushreegn@gmail.com',
  },

  {
    id: 47,
    type: 'editorial board',
    src: '/photos/members/hetarth.png',
    name: 'Hetarth Jain',
    post: 'EDITOR',
    linkedin:
      ' https://www.linkedin.com/in/hetarth-jain?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'hetarth.jain@gmail.com',
  },
  {
    id: 48,
    type: 'editorial board',
    src: '/photos/members/Shreekara Bhat.jpg',
    name: 'Shreekara Bhat',
    post: 'EDITOR',
    linkedin:
      'https://www.linkedin.com/in/shreekara-bhat-309a35254?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'shreekarabhat04@gmail.com',
  },
]

export const senior2022_team_photo = [
  {
    id: 1,
    type: 'executive_committee',
    src: '/photos/members/Chinmaya1.JPG',
    name: 'Chinmaya K',
    post: 'CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/chinmaya-k-2ab51221a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    gmail: 'chinmayak77@gmail.com',
  },
  {
    id: 2,
    type: 'executive_committee',
    src: '/photos/members/Shivraj.JPG',
    name: 'Shivraj Nath',
    post: 'VICE CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/shivraj-nath-892244124?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: '18srjn@gmail.com',
  },
  {
    id: 3,
    type: 'executive_committee',
    src: '/photos/members/Surendra.jpg',
    name: 'Surendra R Hegde',
    post: 'GENERAL SECRETARY',
    linkedin: 'https://www.linkedin.com/in/surendra-hegde-9b9042106',
    gmail: 'surendrahegde3@gmail.com',
  },
  {
    id: 4,
    type: 'executive_committee',
    src: '/photos/members/Ankit.jpg',
    name: 'Ankit Priyesh ',
    post: 'SAC CO-ORDINATOR',
    linkedin:
      'https://www.linkedin.com/in/ankit-priyesh-101505247?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'priyesh.ankit3@gmail.com',
  },
  {
    id: 5,
    type: 'executive_committee',
    src: '/photos/members/AMRUTHA.png',
    name: 'Amrutha K R',
    post: 'SAC CO-ORDINATOR',
    linkedin:
      'https://www.linkedin.com/in/amrutha-k-r-6315aa1b6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'amruthaputti01@gmail.com',
  },
  {
    id: 6,
    type: 'executive_committee',
    src: '/photos/members/Mandara.jpg',
    name: 'Mandara Shivakumar',
    post: 'EVENT MANAGER',
    linkedin:
      'https://www.linkedin.com/in/mandara-shivakumar-a848171b0?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'mandarashivakumar@gmail.com',
  },
  {
    id: 7,
    type: 'executive_committee',
    src: '/photos/members/Sparshi.jpg',
    name: 'Sparshi Agrawal',
    post: 'REPORTING LEAD',
    linkedin:
      'https://www.linkedin.com/in/sparshi-agrawal-28ba87218?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'sparshi.agrawal091@gmail.com',
  },
  {
    id: 8,
    type: 'executive_committee',
    src: '/photos/members/Hamsini.jpg',
    name: 'Hamsini V',
    post: 'PUBLICITY LEAD',
    linkedin:
      'https://www.linkedin.com/in/hamsini-v-37495221a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'hamsinisvm@gmail.com',
  },
  {
    id: 9,
    type: 'executive_committee',
    src: '/photos/members/IMG_20230617_122843_Bokeh.jpg',
    name: 'C A Akhil',
    post: 'JOINT SECRETARY',
    linkedin: 'https://www.linkedin.com/in/akhil-c-a-642606246',
    gmail: 'akhilca232002@gmail.com',
  },
  {
    id: 10,
    type: 'executive_committee',
    src: '/photos/members/Prajwal.jpg',
    name: 'Prajwal K',
    post: 'TREASURER',
    linkedin:
      'https://www.linkedin.com/in/prajwal2727?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    gmail: 'prajwalrao123@gmail.com',
  },
  {
    id: 11,
    type: 'executive_committee',
    src: '/photos/members/Sneha N Angadi.jpg',
    name: 'Sneha N Angadi',
    post: 'CHIEF STUDENT CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/chaithanya-p-58b718296',
    gmail: '',
  },
  {
    id: 12,
    type: 'executive_committee',
    src: '/photos/members/K Ganapathi sharma.jpg',
    name: 'K Ganpathi Sharma',
    post: 'STUDENT CO-ORDINATOR',
    linkedin: 'www.linkedin.com/in/ganapathi-sharma',
    gmail: 'ganapathisharma2003@gmail.com',
  },
  {
    id: 13,
    type: 'executive_committee',
    src: '/photos/members/Sriharihara Kashyap.S.jpg',
    name: 'Sriharihara Kashyap S',
    post: 'STUDENT CO-ORDINATOR',
    linkedin: '',
    gmail: '',
  },
  {
    id: 14,
    type: 'executive_committee',
    src: '/photos/members/Sohan S.jpg',
    name: 'Sohan S',
    post: 'PUBLICITY CO-ORDINATOR',
    linkedin: 'https://bit.ly/Linkedin_Abdulrahiman',
    gmail: 'sohansnair1563717@gmail.com',
  },
  {
    id: 15,
    type: 'executive_committee',
    src: '/photos/members/Meghana.jpg',
    name: 'Meghana',
    post: 'REPORTING TEAM',
    linkedin:
      's://www.linkedin.com/in/meghana-ballal-735314212?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'meghanaballal02@gmail.com',
  },
  {
    id: 16,
    type: 'executive_committee',
    src: '/photos/members/Deeksha Hegde.PNG',
    name: 'Deeksha Hegde',
    post: 'REPORTING TEAM',
    linkedin:
      'https://www.linkedin.com/in/deeksha-hegde-890407210?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    gmail: 'deekshahegde321@gmail.com',
  },
  {
    id: 17,
    type: 'MEMBERSHIP DEVELOPMENT COMMITTEE',
    src: '/photos/members/Suyash.jpg',
    name: 'Suyash G Patil',
    post: 'CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/shonigp?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'suyash.g.patil@gmail.com',
  },
  {
    id: 18,
    type: 'MEMBERSHIP DEVELOPMENT COMMITTEE',
    src: '/photos/members/AMRUTHA.png',
    name: 'Amrutha K R',
    post: 'VICE CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/amrutha-k-r-6315aa1b6?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'amruthaputti01@gmail.com',
  },
  {
    id: 19,
    type: 'MEMBERSHIP DEVELOPMENT COMMITTEE',
    src: '/photos/members/Prakruthi K P .jpg',
    name: 'Prakruti K P',
    post: 'SECRETARY',
    linkedin:
      'https://www.linkedin.com/in/prakruthi-k-p-787313212?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'prakruthikp17@gmail.com',
  },
  {
    id: 20,
    type: 'MEMBERSHIP DEVELOPMENT COMMITTEE',
    src: '/photos/members/Sumukh J Kowligi.jpg',
    name: 'Sumukh J Kowligi',
    post: 'AMBASSADOR',
    linkedin:
      'https://www.linkedin.com/in/sumukhkowligi?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'sumukhkowligi@gmail.com',
  },
  {
    id: 21,
    type: 'Electron Devices Society',
    name: 'Mukul',
    src: '/photos/members/Mukul1.jpg',
    post: 'CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/mukul-pathak-a367a61b3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'mukulkr21799@gmail.com',
  },
  {
    id: 22,
    type: 'Electron Devices Society',
    name: 'Sushma B S',
    src: '/photos/members/Sushma1.jpeg',
    post: 'VICE CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/sushma-bs?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'sushma.bananda@gmail.com',
  },
  {
    id: 23,
    type: 'Electron Devices Society',
    src: '/photos/members/Abdullah Meeran MH.jpg',
    name: 'Abdullah Meeran M H',
    post: 'SECRETARY',
    linkedin: 'https://www.linkedin.com/in/abdullah-meeran-mh-31a913236',
    gmail: 'abdullahmeeran18@gmail.com ',
  },
  {
    id: 24,
    type: 'Electron Devices Society',
    src: '/photos/members/P A Anshad.jpg',
    name: 'P A Anshad',
    post: 'JOINT SECRETARY',
    linkedin: 'https://www.linkedin.com/in/anshad-p-a-66329a213/',
    gmail: 'anshadpa720@gmail.com',
  },
  {
    id: 25,
    type: 'Electron Devices Society',
    src: '/photos/members/Ashwin V Bhandari.JPG',
    name: 'Ashwin Vijayakumar Bhandari',
    post: 'TECHNICAL CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/ashwin-bhandari396/',
    gmail: 'ashwinvbhandari@gmail.com',
  },
  {
    id: 26,
    type: 'women in engineering',
    src: '/photos/members/Saswathi.jpeg',
    name: 'D Saswathi Srichhakri',
    post: 'CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/d-saswathi-srichakri-07b772245?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'saswathi.chakri@gmail.com',
  },
  {
    id: 27,
    type: 'women in engineering',
    src: '/photos/members/Vanditha.jpg',
    name: 'Vanditha M',
    post: 'VICE CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/vanditha07?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'mvanditha07@gmail.com',
  },
  {
    id: 28,
    type: 'women in engineering',
    src: '/photos/members/Sudhanva.jpeg',
    name: 'Sudhanva R',
    post: 'SENIOR EVENT CO-ORDINATOR',
    linkedin:
      'https://www.linkedin.com/in/sudhanva-ramesh-678505214?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'sudhanvar2001@gmail.com ',
  },
  {
    id: 29,
    type: 'women in engineering',
    src: '/photos/members/Smrithi Bhat.jpg',
    name: 'Smrithi Bhat',
    post: 'SECRETARY',
    linkedin: 'https://www.linkedin.com/in/smrithi-bhat-139313212/ ',
    gmail: 'smrithib0602@gmail.com',
  },
  {
    id: 30,
    type: 'women in engineering',
    src: '/photos/members/IMG_9439.JPG',
    name: 'Umar Ahmed',
    post: 'JOINT SECRETARY',
    linkedin: 'https://www.linkedin.com/in/umar-ahmad-7a0b73245 ',
    gmail: 'umarahmad10786@gmail.com',
  },

  {
    id: 31,
    type: 'women in engineering',
    src: '/photos/members/neha_tg.jpg',
    name: 'Neha T G',
    post: 'Senior Technical Coordinator',
    linkedin: 'https://www.linkedin.com/in/neha-t-g-832340231',
    gmail: 'nehatg504@gmail.com',
  },
  {
    id: 32,
    type: 'women in engineering',
    src: '/photos/members/Ananya A.jpg',
    name: 'Ananya A',
    post: 'EVENT CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/ananyaa21',
    gmail: 'ananyaachendu21@gmail.com',
  },
  {
    id: 33,
    type: 'robotics and automation society',
    src: '/photos/members/Raghavendra.jpg',
    name: 'Raghavendra G',
    post: 'CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/raghavendra-g-b0406a1b7?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'raghavendra422001@gmail.com',
  },
  {
    id: 34,
    type: 'robotics and automation society',
    src: '/photos/members/Snehith.jpg',
    name: 'Snehith K',
    post: 'VICE CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/snehith-k-9b3158218?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: '',
  },
  {
    id: 35,
    type: 'robotics and automation society',
    src: '/photos/members/HARSHITH1.jpg',
    name: 'R Harshith',
    post: 'SENIOR TECHNICAL CO-ORDINATOR',
    linkedin:
      'https://www.linkedin.com/in/r-harshith-313211192?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'rharshith2697@gmail.com',
  },
  {
    id: 36,
    type: 'robotics and automation society',
    src: '/photos/members/Gururaj Deepak Todurkar .jpeg',
    name: 'Gururaj Deepak Todurkar',
    post: 'SECRETARY',
    linkedin: 'https://www.linkedin.com/in/gururaj-todurkar-b0579a240',
    gmail: 'gururajdtodurkar7@gmail.com',
  },
  {
    id: 37,
    type: 'robotics and automation society',
    src: '/photos/members/krishnakumar.jpg',
    name: 'Krishnakumar Balachandra Bhat',
    post: 'JOINT SECRETARY',
    linkedin: 'https://www.linkedin.com/in/20181729/',
    gmail: 'krishnakumarbhat02@gmail.com',
  },
  {
    id: 38,
    type: 'robotics and automation society',
    src: '/photos/members/Darshan D Kerkar .jpg',
    name: 'Darshan D Kerkar',
    post: 'TECHNICAL CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/darshan-kerkar-94a26427a',
    gmail: 'darshankerkar04@gmail.com',
  },
  {
    id: 39,
    type: 'web development board',
    src: '/photos/members/Shomik.jpg',
    name: 'Shomik Ghosh',
    post: 'CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/shomik-ghosh-01016a190?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'shomikghosh2020@gmail.com',
  },
  {
    id: 40,
    type: 'web development board',
    src: '/photos/members/IMG20210307144705 1.jpg',
    name: 'Debojyoti Koley',
    post: 'CO-ORDINATOR',
    linkedin: 'https://www.linkedin.com/in/debojyoti-koley-2268071a8/',
    gmail: 'debojyoti.koley.dk@gmail.com',
  },
  {
    id: 41,
    type: 'editorial board',
    src: '/photos/members/Shukla.JPG',
    name: 'P Shukla',
    post: 'CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/p-shukla-1010s1010?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'shuklaparthasarathy@gmail.com',
  },
  {
    id: 42,
    type: 'editorial board',
    src: '/photos/members/RASHMI.jpg',
    name: 'RASHMI R',
    post: 'VICE CHAIRPERSON',
    linkedin:
      'https://www.linkedin.com/in/rashmi-r-ab494a235?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'rashmirahul21@gmail.com',
  },
  {
    id: 43,
    type: 'editorial board',
    src: '/photos/members/hardik.jpg',
    name: 'Hardik Shettigar',
    post: 'SENIOR EDITOR',
    linkedin:
      'https://www.linkedin.com/in/hardik-shettigar-b7085218a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'hardikhard28@gmail.com',
  },
  {
    id: 44,
    type: 'editorial board',
    src: '/photos/members/Chandana.jpg',
    name: 'Chandana Raju',
    post: 'SENIOR EDITOR',
    linkedin:
      'https://www.linkedin.com/in/chandana-raju?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    gmail: 'chandanaraje1503@gmail.com',
  },
  {
    id: 45,
    type: 'editorial board',
    src: '/photos/members/IMG20220908082302 (2).jpg',
    name: 'Pushpesh Joshi',
    post: 'GRAPHIC DESIGNER',
    linkedin: 'https://www.linkedin.com/in/pushpesh-joshi-5493101b8',
    gmail: 'joshi.pushpesh09@gmail.com',
  },
  {
    id: 46,
    type: 'editorial board',
    src: '/photos/members/Priyanshu  Tripathi .jpg',
    name: 'Priyanshu Kumar Tripathi',
    post: 'GRAPHIC DESIGNER',
    linkedin: 'https://www.linkedin.com/in/priyanshu-kr-tripathi-4658a822a',
    gmail: 'vashu.tripathi23@gmail.com',
  },
  {
    id: 47,
    type: 'editorial board',
    src: '/photos/members/Vindya K.jpg',
    name: 'Vindya K',
    post: 'EDITOR',
    linkedin: 'https://www.linkedin.com/in/vindya-k/',
    gmail: 'kvindya404@gmail.com',
  },
  {
    id: 48,
    type: 'editorial board',
    src: '/photos/members/IMG-20231028-WA0076.jpg',
    name: 'Adya S',
    post: 'EDITOR ',
    linkedin: 'https://www.linkedin.com/in/adya-sujeet-168288254  ',
    gmail: 'adyasujeet@yahoo.com',
  },
]  